<template>
  <page>
    <div slot="headerLeft">
      <!-- <el-select v-model="selectType" @change="changeHandler">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select> -->
      <el-form inline>
        <el-form-item :label="$t('deviceList.sn')">
          <el-input
            :placeholder="$t('deviceList.sn')"
            v-model.trim="params.sn"
            @keyup.enter.native="replaceData"
            clearable
          />
        </el-form-item>

        <el-form-item :label="$t('activeDevice.state')">
          <el-select clearable v-model="params.onlineStatus">
            <el-option
              v-for="item in status"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item :label="$t('deviceList.appsInstalled')">
          <el-select multiple collapse-tags clearable v-model="selectApps">
            <el-option
              v-for="item in appList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div style="margin-bottom:15px;">
      <el-button type="primary" plain @click="replaceData()">{{ $t('query') }}</el-button>
      <!-- <el-button type="primary" v-show="multipleSelection.length" @click="moveGroupVisible = true"
        >移动分组</el-button
      > -->
      <el-button size="medium" plain @click="resetHandler">{{ $t('deviceList.reset') }}</el-button>
      <el-button type="danger" plain @click="delHandler(true)" v-if="btnShow && btnShow.delete">{{
        $t('delete')
      }}</el-button>

      <el-button type="primary" @click="batchPublish" v-if="btnShow && btnShow.publish">{{
        $t('customerHome.batchPublish')
      }}</el-button>
      <el-button
        v-if="btnShow && btnShow.deviceGroup"
        type="primary"
        @click="
          () => {
            if (!multipleSelection.length) {
              $message.warning($t('leastOne'))
              return
            }
            visible3 = true
          }
        "
        >{{ $t('deviceList.deviceGroup') }}</el-button
      >
      <el-dropdown trigger="click" @command="commandHandler($event, 'batch')">
        <el-button @click="currentItem = null" type="primary">
          {{ $t('more') }}<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-if="btnShow && btnShow.turnOffWifi" :command="1">{{
            $t('deviceList.closeWifi')
          }}</el-dropdown-item>
          <el-dropdown-item v-if="btnShow && btnShow.turnOffVirtual" :command="2">{{
            $t('deviceList.closeVirtualKeys')
          }}</el-dropdown-item>
          <el-dropdown-item v-if="btnShow && btnShow.turnOnVirtual" :command="3">{{
            $t('deviceList.openVirtualKeys')
          }}</el-dropdown-item>
          <el-dropdown-item v-if="btnShow && btnShow.restart" :command="4">{{
            $t('deviceList.restart')
          }}</el-dropdown-item>
          <el-dropdown-item :command="12" v-if="btnShow && btnShow.timedRestart">{{
            $t('deviceList.timedRestart')
          }}</el-dropdown-item>
          <el-dropdown-item v-if="btnShow && btnShow.recover" :command="5">{{
            $t('deviceList.restoreFactorySettings')
          }}</el-dropdown-item>
          <el-dropdown-item v-if="btnShow && btnShow.timeZone" :command="6">{{
            $t('deviceList.setTimeZone')
          }}</el-dropdown-item>
          <el-dropdown-item v-if="btnShow && btnShow.sleep" :command="7">{{
            $t('deviceList.setSleep')
          }}</el-dropdown-item>
          <el-dropdown-item v-if="btnShow && btnShow.shutdown" :command="8">{{
            $t('deviceList.shutdown')
          }}</el-dropdown-item>
          <!-- <el-dropdown-item :command="13">{{ $t('deviceList.timedSwitch') }}</el-dropdown-item> -->

          <el-dropdown-item v-if="btnShow && btnShow.file" :command="9">{{
            $t('deviceList.transferFiles')
          }}</el-dropdown-item>
          <!-- <el-dropdown-item :command="15" v-if="btnShow && btnShow.retrieve">{{
            $t('deviceList.retrieve')
          }}</el-dropdown-item> -->
          <el-dropdown-item v-if="btnShow && btnShow.screenIntensity" :command="10">{{
            $t('deviceList.screenBrightnessSetting')
          }}</el-dropdown-item>
          <el-dropdown-item v-if="btnShow && btnShow.fontSize" :command="11">{{
            $t('deviceList.fontSizeSettings')
          }}</el-dropdown-item>
          <el-dropdown-item v-if="btnShow && btnShow.openGps" :command="13">{{
            $t('deviceList.openGps')
          }}</el-dropdown-item>
          <el-dropdown-item v-if="btnShow && btnShow.closeGps" :command="14">{{
            $t('deviceList.closeGps')
          }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="con table-wrap">
      <el-tree
        ref="tree"
        class="tree"
        :data="treeData"
        node-key="id"
        default-expand-all
        :expand-on-click-node="false"
        :props="defaultProps"
        draggable
        :allow-drag="allowDrag"
        :allow-drop="allowDrop"
        @node-drag-end="handleDragEnd"
        highlight-current
        @node-click="nodeClick"
        :current-node-key="200"
        v-loading="loading1"
      >
        <div @mouseleave="canDrag = false" class="custom-tree-node" slot-scope="{ node, data }">
          <el-tooltip :open-delay="3000" effect="light" :content="data.name" placement="left">
            <div class="name">
              {{ data.name }}
            </div>
          </el-tooltip>
          <!-- 全部分组不可移动 -->
          <div>
            <el-dropdown class="more" @command="commandHandler2($event, data)">
              <span>
                ●●●
              </span>
              <el-dropdown-menu slot="dropdown">
                <!-- 200 全部分组，201 未分组 -->
                <el-dropdown-item
                  :command="1"
                  :disabled="data.level > 4 || data.id == 201 || !(btnShow && btnShow.addSubgroup)"
                  >{{ $t('deviceList.addSubgroup') }}</el-dropdown-item
                >
                <el-dropdown-item
                  :command="2"
                  v-if="data.id != 200 && data.id != 201 && btnShow && btnShow.renameSubgroup"
                  >{{ $t('deviceList.rename') }}</el-dropdown-item
                >
                <!-- <el-dropdown-item :command="3">{{ $t('deviceList.assignAdmin') }}</el-dropdown-item> -->
                <el-dropdown-item
                  :command="4"
                  v-if="data.id != 200 && data.id != 201 && btnShow && btnShow.deleteSubgroup"
                  >{{ $t('delete') }}</el-dropdown-item
                >
                <!-- <el-dropdown-item :command="5">{{ $t('deviceList.moveUp') }}</el-dropdown-item>
                <el-dropdown-item :command="6">{{ $t('deviceList.moveDown') }}</el-dropdown-item> -->
              </el-dropdown-menu>
            </el-dropdown>
            <el-tooltip effect="light" :content="$t('deviceList.move')" placement="right">
              <span
                v-if="!(data.id == 200 || data.id == 201)"
                @mouseenter="canDrag = true"
                class="el-icon-sort"
              ></span>
            </el-tooltip>
          </div>
        </div>
      </el-tree>

      <div class="table-wrap">
        <el-table
          ref="table"
          :data="tableData"
          class="table"
          border
          row-key="id"
          v-loading="loading"
          height="100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            v-if="btnShow && btnShow.delete"
            type="selection"
            :reserve-selection="true"
            width="55"
          />

          <el-table-column min-width="300" show-overflow-tooltip :label="$t('deviceList.sn')">
            <template slot-scope="{ row }">
              <!-- <img style="width:28px;height:28px;margin-right:2px" :src="row.image" /> -->
              <span style="position: relative;top: -3px;">{{ row.sn }}</span>
              <img
                v-if="row.bindingRelationship == 1"
                src="../../../../../../assets/img/bind.png"
                class="bindIcon"
              />
            </template>
          </el-table-column>

          <el-table-column
            show-overflow-tooltip
            prop="modelName"
            width="150"
            :label="$t('deviceList.deviceType')"
          />
          <el-table-column
            show-overflow-tooltip
            prop="onlineStatus"
            :label="$t('activeDevice.state')"
          >
            <template slot-scope="{ row }">
              <span :style="{ color: row.onlineStatus === 0 ? '#31ac6d' : '#F56C6C' }">{{
                row.onlineStatus === 0 ? $t('deviceList.onLine') : $t('deviceList.offLine')
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            prop="groupName"
            width="150"
            :label="$t('deviceList.deviceGroup')"
          />
          <el-table-column
            show-overflow-tooltip
            prop="spName"
            :label="$t('deviceList.affiliatedStore')"
          />
          <el-table-column
            show-overflow-tooltip
            prop="store"
            :label="$t('organManagement.remark')"
          />

          <el-table-column
            show-overflow-tooltip
            prop="firmwareVersion"
            width="150"
            :label="$t('deviceList.firmwareVersion')"
          />
          <el-table-column
            show-overflow-tooltip
            width="120"
            prop="totalOnlineTimes"
            :label="$t('deviceList.onlineTimes')"
          />

          <el-table-column
            show-overflow-tooltip
            width="160"
            :label="$t('deviceList.lastOnlineTime')"
          >
            <template slot-scope="{ row }">
              {{ $utils.formatTime(row.time) }}
            </template>
          </el-table-column>

          <el-table-column show-overflow-tooltip width="160" :label="$t('deviceList.createdTime')">
            <template slot-scope="{ row }">
              {{ $utils.formatTime(row.createTime) }}
            </template>
          </el-table-column>

          <el-table-column
            prop="address"
            show-overflow-tooltip
            width="200"
            :label="$t('deviceList.detailedAddress')"
          />

          <el-table-column fixed="right" width="340" :label="$t('operation')">
            <template slot-scope="{ row }">
              <div class="operateRow">
                <el-button v-if="btnShow && btnShow.detail" type="text" @click="detail(row)">{{
                  $t('details')
                }}</el-button>
                <el-button v-if="btnShow && btnShow.edit" type="text" @click="editHandler(row)">{{
                  $t('edit')
                }}</el-button>
                <el-button
                  v-if="btnShow && btnShow.remoteAssistance"
                  type="text"
                  @click="remoteAssistance(row)"
                  >{{ $t('deviceList.remote') }}</el-button
                >
                <el-button
                  v-if="btnShow && btnShow.remoteSdAssistance"
                  type="text"
                  @click="newWindowOpen(row)"
                  >{{ $t('deviceList.sedinittaRemote') }}</el-button
                >
                <el-button
                  v-if="btnShow && btnShow.sedinittaRetrieve"
                  type="text"
                  @click="retrieveSd(row)"
                  >{{ $t('deviceList.sedinittaRetrieve') }}</el-button
                >
                <el-button v-if="btnShow && btnShow.publish" type="text" @click="publish(row.sn)">{{
                  $t('publishApp.release')
                }}</el-button>
                <el-dropdown trigger="click" @command="commandHandler($event, 'row', row)">
                  <el-button @click="currentItem = row" type="text">
                    {{ $t('more') }}<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-if="btnShow && btnShow.turnOffWifi" :command="1">{{
                      $t('deviceList.closeWifi')
                    }}</el-dropdown-item>
                    <el-dropdown-item
                      v-if="btnShow && btnShow.turnOffVirtual && row.systemType !== 2"
                      :command="2"
                      >{{ $t('deviceList.closeVirtualKeys') }}</el-dropdown-item
                    >
                    <el-dropdown-item
                      v-if="btnShow && btnShow.turnOnVirtual && row.systemType !== 2"
                      :command="3"
                      >{{ $t('deviceList.openVirtualKeys') }}</el-dropdown-item
                    >
                    <el-dropdown-item v-if="btnShow && btnShow.restart" :command="4">{{
                      $t('deviceList.restart')
                    }}</el-dropdown-item>
                    <el-dropdown-item :command="12" v-if="btnShow && btnShow.timedRestart">{{
                      $t('deviceList.timedRestart')
                    }}</el-dropdown-item>
                    <el-dropdown-item
                      v-if="btnShow && btnShow.recover && row.systemType !== 2"
                      :command="5"
                      >{{ $t('deviceList.restoreFactorySettings') }}</el-dropdown-item
                    >
                    <el-dropdown-item
                      v-if="btnShow && btnShow.timeZone && row.systemType !== 2"
                      :command="6"
                      >{{ $t('deviceList.setTimeZone') }}</el-dropdown-item
                    >
                    <el-dropdown-item
                      v-if="btnShow && btnShow.sleep && row.systemType !== 2"
                      :command="7"
                      >{{ $t('deviceList.setSleep') }}</el-dropdown-item
                    >
                    <el-dropdown-item v-if="btnShow && btnShow.shutdown" :command="8">{{
                      $t('deviceList.shutdown')
                    }}</el-dropdown-item>
                    <!-- <el-dropdown-item :command="13">{{ $t('deviceList.timedSwitch') }}</el-dropdown-item> -->

                    <el-dropdown-item v-if="btnShow && btnShow.file" :command="9">{{
                      $t('deviceList.transferFiles')
                    }}</el-dropdown-item>
                    <el-dropdown-item :command="15" v-if="btnShow && btnShow.retrieve">{{
                      $t('deviceList.retrieve')
                    }}</el-dropdown-item>
                    <el-dropdown-item
                      v-if="btnShow && btnShow.screenIntensity && row.systemType !== 2"
                      :command="10"
                      >{{ $t('deviceList.screenBrightnessSetting') }}</el-dropdown-item
                    >
                    <el-dropdown-item
                      v-if="btnShow && btnShow.fontSize && row.systemType !== 2"
                      :command="11"
                      >{{ $t('deviceList.fontSizeSettings') }}</el-dropdown-item
                    >
                    <el-dropdown-item
                      v-if="btnShow && btnShow.openGps && row.systemType !== 2"
                      :command="13"
                      >{{ $t('deviceList.openGps') }}</el-dropdown-item
                    >
                    <el-dropdown-item
                      v-if="btnShow && btnShow.closeGps && row.systemType !== 2"
                      :command="14"
                      >{{ $t('deviceList.closeGps') }}</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>

                <!-- <el-button
              v-if="btnShow && btnShow.delete"
              type="danger"
             
              @click="delHandler(false, row.sn)"
              >删除</el-button
            > -->
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <pagination
      slot="pagination"
      :total="total"
      :page-size.sync="params.pageSize"
      :page.sync="params.currentPage"
      @change="getData"
    />
    <add-device-modal
      @getData="replaceData"
      :groupList="groupList"
      :modelList="modelList"
      :currentItem="currentItem"
      :spName="spName"
      :visible.sync="visible"
      :treeData="treeData"
      v-if="visible"
    />

    <add-moredevice-modal @getData="replaceData" :visible.sync="visible2" v-if="visible2" />

    <select-app-modal
      @getData="replaceData"
      v-if="selectAppVisible"
      :visible.sync="selectAppVisible"
      :currentSn="currentSn"
    />

    <time-zone-modal
      :currentItem="currentItem"
      :visible.sync="timeZonevisible"
      v-if="timeZonevisible"
      :multipleSelection="multipleSelection"
    />

    <shut-down-modal
      :currentItem="currentItem"
      :visible.sync="shutdownvisible"
      v-if="shutdownvisible"
      :multipleSelection="multipleSelection"
    />

    <sleep-modal
      :currentItem="currentItem"
      :visible.sync="sleepvisible"
      v-if="sleepvisible"
      :multipleSelection="multipleSelection"
    />

    <light-modal
      :currentItem="currentItem"
      :visible.sync="lightvisible"
      v-if="lightvisible"
      :multipleSelection="multipleSelection"
    />

    <font-modal
      :currentItem="currentItem"
      :visible.sync="fontvisible"
      v-if="fontvisible"
      :multipleSelection="multipleSelection"
    />

    <remote-modal
      :btnShow="btnShow"
      :currentItem="currentItem"
      :frameUrl="frameUrl"
      :visible.sync="remotevisible"
      v-if="remotevisible"
      @command="commandHandler"
    />

    <file-modal
      :currentItem="currentItem"
      :visible.sync="filevisible"
      v-if="filevisible"
      :multipleSelection="multipleSelection"
    />
    <!-- 定时开关机 -->
    <TimedRestartModal :currentItem="currentItem" :visible.sync="timevisible" v-if="timevisible" />
    <!-- 定时重启 -->
    <ScheduledRestartModal
      :currentItem="currentItem"
      :visible.sync="scheduledRestart"
      :multipleSelection="multipleSelection"
      @getData="replaceData"
      v-if="scheduledRestart"
    />

    <!-- 桑达文件取回 -->
    <RetrieveSdModal
      :currentItem="currentItem"
      :visible.sync="retrieveSdVisible"
      v-if="retrieveSdVisible"
    />

    <el-dialog class="remoteDialog" :title="$t('tips')" :visible.sync="dialogVisible" width="30%">
      <div class="content">
        <i class="el-icon-warning"></i>
        {{ $t('deviceList.isNeedRemote') }}
      </div>
      <span slot="footer">
        <el-button @click="dialogVisible = false">{{ $t('cancel') }}</el-button>
        <el-button type="primary" @click="remoteRender(false)">{{
          $t('deviceList.mainScreen')
        }}</el-button>
        <el-button type="primary" @click="remoteRender(true)">{{
          $t('deviceList.secondaryScreen')
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="$t('deviceList.assignAdmin')"
      v-if="dialogTableVisible"
      :visible.sync="dialogTableVisible"
      width="640px"
    >
      <el-table :data="modalData">
        <el-table-column property="userName" :label="$t('sourceManagement.userName')" width="200" />
        <el-table-column property="role" :label="$t('deviceList.role')" width="200" />
        <el-table-column
          property="cellPhone"
          :label="$t('customerStatistical.cellPhone')"
          width="200"
        />
      </el-table>
    </el-dialog>
    <!-- 批量设置设备分组 -->
    <el-dialog
      :title="$t('deviceList.deviceGroup')"
      v-if="visible3"
      :visible.sync="visible3"
      width="640px"
    >
      <p>
        {{ $t('deviceModal.selectGrouping') }}
        <!-- <el-select filterable v-model="groupId" :placeholder="$t('placeholder')">
          <el-option
            v-for="item in groupList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select> -->
        <GroupCascader :options="treeData" :value.sync="groupId" />
      </p>
      <span slot="footer">
        <el-button @click="visible3 = false">{{ $t('cancel') }}</el-button>
        <el-button type="primary" @click="editBatchGroup">{{ $t('confirm') }}</el-button>
      </span>
    </el-dialog>

    <!-- 文件取回弹窗 -->
    <el-dialog
      :title="$t('tips')"
      v-if="retrieveVisible"
      :close-on-click-modal="false"
      :visible.sync="retrieveVisible"
      width="640px"
    >
      <div class="tips">
        <p>{{ $t('deviceList.retrieveTips') }}</p>
        <el-image style="width: 600px;" :src="tips" :preview-src-list="[tips]" />
        <p>
          {{ $t('deviceList.address')
          }}<el-button
            size="mini"
            icon="el-icon-document-copy"
            v-if="retrieveInfo.address"
            @click="copy(retrieveInfo.address)"
            >{{ $t('copy') }}</el-button
          >：{{ retrieveInfo.address || '' }}
        </p>

        <p>
          {{ $t('deviceList.conversation') }}
          <el-button
            size="mini"
            icon="el-icon-document-copy"
            v-if="retrieveInfo.cid"
            @click="copy(retrieveInfo.cid)"
            >{{ $t('copy') }}</el-button
          >：{{ retrieveInfo.cid || '' }}
        </p>
      </div>
      <span slot="footer">
        <el-button @click="retrieveVisible = false">{{ $t('cancel') }}</el-button>
        <el-button type="primary" @click="download">{{ $t('downloadTool') }}</el-button>
      </span>
    </el-dialog>

    <!-- <move-group-modal
      v-if="moveGroupVisible"
      :visible.sync="moveGroupVisible"
      :selection="multipleSelection"
      :group-list="groupList"
    /> -->
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import AddDeviceModal from '../components/AddDeviceModal'
import AddMoredeviceModal from '../components/AddMoredeviceModal'
import TimeZoneModal from '../components/TimeZoneModal'
import SleepModal from '../components/SleepModal'
import LightModal from '../components/LightModal'
import FontModal from '../components/FontModal'
import ShutDownModal from '../components/ShutDownModal'
import RemoteModal from '../components/RemoteModal'
import RetrieveSdModal from '../components/RetrieveSdModal'
// import MoveGroupModal from '../components/MoveGroupModal'
import SelectAppModal from '../components/SelectAppModal'
import FileModal from '../components/FileModal'
import TimedRestartModal from '../components/TimedRestartModal'
import ScheduledRestartModal from '../components/ScheduledRestartModal'
import GroupCascader from '@/pages/index/modules/components/GroupCascader'
import tips from '@/assets/img/tips.png'

import { HOST, BASE_URL, isDev } from '@/lib/constant'

import win from '@/assets/img/win.png'
import android from '@/assets/img/android.png'

export default {
  mixins: [watchParamsGetData],
  components: {
    AddDeviceModal,
    AddMoredeviceModal,
    TimeZoneModal,
    SleepModal,
    ShutDownModal,
    RemoteModal,
    SelectAppModal,
    FileModal,
    LightModal,
    FontModal,
    TimedRestartModal,
    ScheduledRestartModal,
    GroupCascader,
    RetrieveSdModal
    // MoveGroupModal,
  },
  data() {
    return {
      selectType: 1,
      firstIn: true,
      dialogVisible: false,
      // moveGroupVisible: false,
      deviceSn: '',
      loading: false,
      groupList: [],
      modelList: [],
      spName: '',
      timer: null,
      visible: false,
      visible2: false,
      selectAppVisible: false,
      timeZonevisible: false,
      sleepvisible: false,
      shutdownvisible: false,
      filevisible: false,
      remotevisible: false,
      lightvisible: false,
      fontvisible: false,
      currentSn: '',
      currentItem: {},
      tableData: [],
      appList: [],
      multipleSelection: [],
      selectApps: [],
      status: [
        {
          label: this.$t('deviceList.onLine'),
          value: 0
        },
        {
          label: this.$t('deviceList.offLine'),
          value: 1
        }
      ],
      options: [
        {
          label: this.$t('deviceList.sn'),
          value: 1
        },
        {
          label: this.$t('deviceList.store'),
          value: 2
        },
        {
          label: this.$t('deviceList.onLine'),
          value: 3
        },
        {
          label: this.$t('customerHome.installSpecifiedApp'),
          value: 4
        }
      ],
      frameUrl: '',
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      treeData: [],
      dialogTableVisible: false,
      modalData: [],
      visible3: false,
      groupId: '',
      timevisible: false,
      scheduledRestart: false,
      canDrag: false,
      params: {
        groupIds: [],
        sn: '',
        store: '',
        onlineStatus: ''
      },
      retrieveSdVisible: false,
      retrieveVisible: false,
      retrieveInfo: {},
      loading1: false,
      tips
    }
  },
  created() {
    // this._getGroupTree()
  },
  watch: {
    remotevisible(val) {
      if (!val) {
        this.resetData()
      }
    }
  },
  methods: {
    resetHandler() {
      this.params.onlineStatus = undefined
      this.selectApps = []
      this.params.store = undefined
      this.params.sn = undefined
      // 选择全部
      this.params.groupIds = []
      this.$refs.tree.setCurrentKey(200)

      this.getData()
    },
    batchPublish() {
      if (!this.multipleSelection.length) {
        this.$message.warning(this.$t('leastOne'))
        return
      }
      this.currentSn = this.currentSn = this.multipleSelection.join(',')
      this.selectAppVisible = true
    },
    openOrDownloadApp(urlScheme, downloadUrl) {
      var iframe = document.createElement('iframe')
      iframe.style.display = 'none'
      iframe.src = urlScheme
      document.body.appendChild(iframe)
      setTimeout(function() {
        console.log('未安装')
        document.body.removeChild(iframe)
        if (downloadUrl) {
          window.open(downloadUrl, '_self')
        }
      }, 2000)
    },

    commandHandler(val, type, row = null) {
      console.log(type)
      if (type == 'batch' && !this.multipleSelection.length) {
        this.$message.warning(this.$t('leastOne'))
        return
      }
      if (type == 'row') {
        // 选择单行
        this.$refs.table && this.$refs.table.clearSelection()
      }
      if (val === 6) {
        this.timeZonevisible = true
        return
      }

      if (val === 8) {
        this.shutdownvisible = true
        return
      }

      if (val === 9) {
        this.filevisible = true
        return
      }

      if (val === 7) {
        this.sleepvisible = true
        return
      }

      if (val === 10) {
        this.lightvisible = true
        return
      }

      if (val === 11) {
        this.fontvisible = true
        return
      }

      if (val === 12) {
        // 定时重启
        this.scheduledRestart = true
        return
      }
      // if (val === 13) {
      //   // 定时开关机
      //   this.timevisible = true
      //   return
      // }

      if (val === 15) {
        // this.openOrDownloadApp('weixin://')
        if (row) {
          this.retrieve(row)
        }
        return
      }

      let tips = this.$t('deviceList.closeWifi')
      let successTips = this.$t('deviceList.alreadyCloseWifi')
      switch (val) {
        case 1:
          tips = this.$t('deviceList.closeWifi')
          successTips = this.$t('deviceList.alreadyCloseWifi')
          break
        case 2:
          tips = this.$t('deviceList.closeVirtualKeys')
          successTips = this.$t('deviceList.alreadyCloseVirtualKeys')
          break
        case 3:
          tips = this.$t('deviceList.openVirtualKeys')
          successTips = this.$t('deviceList.alreadyOpenVirtualKeys')
          break
        case 4:
          tips = this.$t('deviceList.restart')
          successTips = this.$t('deviceList.restartSuccess')
          break
        case 5:
          tips = this.$t('deviceList.restoreFactorySettings')
          successTips = this.$t('deviceList.restoreFactorySettingsSuccess')
          break
        case 13:
          tips = this.$t('deviceList.openGps')
          successTips = this.$t('succeeded')
          break
        case 14:
          tips = this.$t('deviceList.closeGps')
          successTips = this.$t('succeeded')
          break
      }
      // type=='batch' 批量操作
      let sn = type == 'batch' ? this.multipleSelection.join(',') : this.currentItem.sn

      this.$confirm(
        `${
          type == 'batch'
            ? this.$t('deviceList.selectNums', { nums: this.multipleSelection.length })
            : ''
        }${this.$t('deviceList.wantTo')} ${tips}?`,
        this.$t('tips'),
        {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel')
        }
      )
        .then(async () => {
          const params = {
            deviceSn: sn,
            type: val
          }

          this.loading = true

          try {
            await this.$api.deviceManagement.publishRemoteAssistance(params)

            if (type == 'batch') {
              this.$refs.table && this.$refs.table.clearSelection()
            }
            this.getData()

            this.$message.success(`${successTips}！`)
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }
        })
        .catch(() => {})
    },
    //批量修改分组
    async editBatchGroup() {
      try {
        if (!this.groupId) {
          this.$message.warning(this.$t('customerHome.pleaseSelectGroup'))
          return
        }
        let params = {
          groupId: this.groupId,
          sns: this.multipleSelection
        }
        await this.$api.deviceManagement.editBatchGroup(params)
        this.$message.success(this.$t('groupManagement.modifySuccess'))
        this.visible3 = false
        this.$refs.table && this.$refs.table.clearSelection()
        this.getData()
      } catch (e) {
        console.log(e)
      }
    },
    async commandHandler2(val, data) {
      if (val == 1) {
        // 新增子分组
        this.addGroup(true, data)
      }
      if (val == 2) {
        // 重命名子分组
        this.addGroup(false, data)
      }
      if (val == 3) {
        // 分配管理员
        this.dialogTableVisible = true
      }
      if (val == 4) {
        // 删除
        try {
          await this.$confirm(this.$t('deviceList.delGroup'), this.$t('delete'), {
            confirmButtonText: this.$t('confirm'),
            cancelButtonText: this.$t('cancel')
          })
          this.delGroup(data)
        } catch (e) {
          console.log(e)
        }
      }
      // if (val == 5) {
      //   //上移
      // }
      // if (val == 6) {
      //   //下移
      // }
    },
    async delGroup(data) {
      try {
        let params = [data.id]
        // 子分组也要传

        let recursion = list => {
          list.forEach(item => {
            params.push(item.id)
            if (item.children && item.children.length > 0) {
              recursion(item.children)
            }
          })
        }
        if (data.children && data.children.length > 0) {
          recursion(data.children)
        }

        await this.$api.deviceManagement.groupDel(params)
        this.$message.success(this.$t('succeeded'))
        this._getGroupTree()
      } catch (e) {
        console.log(e)
      }
    },
    allowDrag(draggingNode) {
      return this.canDrag
    },
    allowDrop(draggingNode, dropNode, type) {
      if (draggingNode.data.level == dropNode.data.level && type != 'inner') {
        // 只可以同层级移动
        return true
      } else {
        console.log('不在同层级内')
        // this.$message.warning(this.$t('deviceList.noMove'))
        return false
      }
    },
    async handleDragEnd(draggingNode, dropNode, dropType, ev) {
      console.log(draggingNode, dropNode)
      if (draggingNode.id == dropNode.id) {
        return
      } else {
        let sendData = []
        const level = draggingNode.level
        const pId = draggingNode.data.parentId
        console.log(level, pId, this.treeData)
        if (level > 1) {
          let data = this.findParentItem(this.treeData, pId)
          console.log(data?.name)
          if (data) {
            try {
              let list = (data?.children || []).map((item, index) => {
                return {
                  ...item,
                  serialNo: index
                }
              })
              await this.$api.deviceManagement.batchUpdate(list)
              this._getGroupTree()
            } catch (e) {
              console.log(e)
            }
          }
        }
      }
    },
    findParentItem(data, pId) {
      let res = null
      if (Array.isArray(data)) {
        let len = data.length
        for (let i = 0; i < len; i++) {
          if (data[i].id === pId) {
            res = data[i]
            console.log('res000', res, pId)
            return res
          } else {
            if (data[i]?.children && data[i]?.children.length > 0 && !res) {
              console.log(4444, data[i].children)
              res = this.findParentItem(data[i].children, pId)
            }
          }
        }
      }
      console.log('res2', res)
      return res
    },
    getIds(data) {
      let res = []
      // 查询全部分组时 传 空数组
      if (data.id == 200) {
        return []
      } else {
        res.push(data.id)
      }
      if (data.children && data.children.length > 0) {
        data.children.forEach(item => {
          res = res.concat(this.getIds(item))
        })
      }
      return res
    },
    nodeClick(data) {
      this.loading = true
      let groupIds = this.getIds(data)
      this.params.groupIds = groupIds
      this.renderData()
    },
    async addGroup(flag, data) {
      try {
        let s = await this.$prompt(
          '',
          flag ? this.$t('deviceList.addSubgroup') : this.$t('deviceList.rename'),
          {
            closeOnClickModal: false,
            inputValue: flag ? '' : data.name,
            confirmButtonText: this.$t('confirm'),
            cancelButtonText: this.$t('cancel'),
            inputPlaceholder: this.$t('pleaseEnter'),
            inputValidator: value => {
              if (!value || !value?.length) {
                return this.$t('customerHome.enterGroupName')
              }
              if (value && value.length > 30) {
                return this.$t('deviceList.char30')
              }
              return true
            }
          }
        )
        let value = s?.value || ''
        let { serialNo, level, id } = data
        if (flag) {
          //添加子分组
          let params = {
            name: value,
            description: value,
            serialNo: 1, // 固定传1
            level: Number(level) + 1,
            parentId: id
          }
          await this.$api.deviceManagement.groupAddChild(params)
        } else {
          // 重命名
          let params2 = {
            name: value,
            description: value,
            id
          }
          await this.$api.deviceManagement.groupUpdateChild(params2)
        }
        this.$message.success(this.$t('succeeded'))
        this._getGroupTree()
      } catch (e) {
        console.log(e)
      }
    },

    async getSelectData() {
      let result = await Promise.all([
        this.$api.deviceManagement.groupList({ pageSize: -1 }),
        this.$api.deviceManagement.modelList({ pageSize: -1 }),
        this.$api.appManagement.allApp({}),
        this.$api.systemManagement.spInfo(),
        this._getGroupTree()
      ])

      if (result[0].result) {
        this.groupList = result[0].result.list.map(item => {
          return {
            label: item.name,
            value: item.id
          }
        })
      }

      if (result[1].result) {
        this.modelList = result[1].result.list.map(item => {
          return {
            label: item.name,
            value: item.id
          }
        })
      }

      if (result[2].result) {
        this.appList = result[2].result.map(item => {
          return {
            label: item.appName,
            value: item.appId
          }
        })
      }

      if (result[3].result) {
        this.spName = result[3].result.name
      }
    },
    async getData(loading = true) {
      loading ? (this.loading = true) : ''
      if (this.firstIn) {
        try {
          await this.getSelectData()
        } catch (e) {
          console.log(e)
        } finally {
          this.firstIn = false
          this.renderData()
        }
      } else {
        this.renderData()
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val.map(item => item.sn)
      // console.log('multipleSelection', this.multipleSelection)
    },
    async renderData() {
      try {
        // console.log('data', this.params)
        let params = { ...this.params }
        if (params.groupIds && params.groupIds.includes(201)) {
          // 查询未分组 201 时 传groupId
          params.groupIds = undefined
          params.groupId = 201
        } else if (!params.groupIds) {
          params.groupId = undefined
        }

        params.appIds = this.selectApps.join(',')

        if (params.store === '') {
          params.store = null
        }

        const res = await this.$api.deviceManagement.deviceList(params)

        if (res.result) {
          this.tableData = res.result.list.map(item => {
            item.time = item.lastOnlineTime

            item.sysyemType = item.type || 1 //测试

            item.image = item.sysyemType == 1 ? android : win

            if (item.groups && item.groups.length) {
              item.groupName = item.groups[0] && item.groups[0].name
            }

            return item
          })
          this.total = res.result.total
        } else {
          this.tableData = []
          this.total = 0
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },

    detail(row) {
      sessionStorage.setItem('currentRow', JSON.stringify(row))
      this.$router.push(`/deviceManagement/deviceList/deviceListDetail/${row.sn}/${row.model}`)
    },
    editHandler(row) {
      this.currentItem = row
      this.visible = true
    },

    openWindow(url, windowName, width, height) {
      /*居中*/
      // var x = parseInt(screen.width / 2.0) - width / 2.0
      // var y = parseInt(screen.height / 2.0) - height / 2.0

      /*全屏*/
      var x = 0
      var y = 0
      var width = screen.width
      var height = screen.height

      var win = null
      var p = 'resizable=1,location=no,scrollbars=no,width='
      p = p + width
      p = p + ',height='
      p = p + height
      p = p + ',left='
      p = p + x
      p = p + ',top='
      p = p + y
      win = window.open(url, windowName, p)
      // win = window.open(url, windowName)
      if (win) {
        this.timer = setInterval(() => {
          if (win.closed) {
            this.resetData()
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      }
    },
    async resetData() {
      try {
        await this.$axios.get(BASE_URL + '/app/api/publish/remoteExit', {
          params: { deviceSn: this.deviceSn }
        })
      } catch (e) {
        console.log(e)
      }
    },

    async remoteRender(deputyScreen) {
      let width = window.innerWidth * 0.8
      let Height = window.innerHeight * 0.8

      const params = {
        deviceSn: this.currentItem.sn,
        spId: this.currentItem.spId
      }

      this.deviceSn = this.currentItem.sn

      this.loading = true

      try {
        const res = await this.$api.deviceManagement.remoteAssistance(params)

        if (res.result) {
          let remote = this.$t('deviceList.remote')
          this.openWindow(
            `http://${HOST}/remote?address=${res.result.address}&cid=${res.result.cid}&sn=${this.currentItem.sn}`,
            remote,
            width,
            Height
          )
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },

    async remoteAssistance(item) {
      this.currentItem = item

      if (this.timer) {
        this.$message.warning(this.$t('deviceList.deviceIsRemote'))
        return
      }

      if (item.onlineStatus === 1) {
        this.$message.warning(this.$t('deviceList.deviceIsOffline'))
        return
      }

      if (item.sysyemType == 1) {
        this.$confirm(this.$t('deviceList.isNeedRemote'), this.$t('tips'), {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel')
        })
          .then(async () => {
            this.remoteRender(false)
          })
          .catch(() => {})
      } else {
        this.dialogVisible = true
      }
    },
    copy(val = '') {
      this.$utils.copyText(val, this)
    },
    async download() {
      //测试环境 http://138.88.8.112:8888/group1/M00/00/2E/ilgIcGS6TD6AZojtAch_ECliTzg228.rar
      let code = isDev
        ? 'group1/M00/00/2E/ilgIcGS6TD6AZojtAch_ECliTzg228.rar'
        : 'group1/M00/00/0D/wKgABWTS_sWAUIEvAch_ECliTzg361.rar'
      // 默认生产下载地址
      let url = 'https://fdfs.scp.sedsy.com/group1/M00/00/0D/wKgABWTS_sWAUIEvAch_ECliTzg361.rar'

      if (isDev) {
        // let { result = '' } = await this.$api.deviceManagement.getDownLoadUrl(code)
        // url = result
        url = 'http://138.88.8.112:8888/group1/M00/00/2E/ilgIcGS6TD6AZojtAch_ECliTzg228.rar'
      }
      if (url) {
        window.open(url, '_self')
      }
      try {
      } catch (e) {
        console.log(e)
      }
    },
    //文件取回
    async retrieve(item) {
      if (item.onlineStatus === 1) {
        this.$message.warning(this.$t('deviceList.deviceIsOffline'))
        return
      }

      try {
        this.loading = true
        const params = {
          deviceSn: item.sn,
          spId: item.spId
        }
        const res = await this.$api.deviceManagement.retrieve(params)

        if (res.result) {
          this.retrieveInfo = res.result
          this.retrieveVisible = true
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },

    //桑达文件取回
    async retrieveSd(item) {
      if (item.onlineStatus === 1) {
        this.$message.warning(this.$t('deviceList.deviceIsOffline'))
        return
      }
      try {
        this.loading = true
        const res = await this.$api.common.statisticAnalysis({ deviceSn: item.sn, remoteType: 2 })
        if (res.result) {
          this.currentItem = { ...item, result: res.result }
          this.retrieveSdVisible = true
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },

    // async resetData() {
    //   try {
    //     const res = await this.$axios.get(BASE_URL + '/app/api/publish/remoteExit', {
    //       params: { deviceSn: this.deviceSn }
    //     })
    //   } catch (e) {
    //     console.log(e)
    //   }
    // },

    // async remoteAssistance(item) {
    //   this.currentItem = item

    //   if (item.onlineStatus === 1) {
    //     this.$message.warning('设备已离线')
    //     return
    //   }

    //   this.$confirm('是否要远程协助?', '提示', {
    //     confirmButtonText: '确认',
    //     cancelButtonText: '取消'
    //   })
    //     .then(async () => {
    //       const params = {
    //         deviceSn: item.sn,
    //         spId: item.spId
    //       }

    //       this.deviceSn = item.sn

    //       this.loading = true

    //       try {
    //         // this.frameUrl = `http://www.baidu.com`
    //         // this.remotevisible = true

    //         const res = await this.$api.deviceManagement.remoteAssistance(params)

    //         if (res.result) {
    //           window.open(
    //             `http://${HOST}/remote?address=${res.result.address}&cid=${res.result.cid}`
    //           )

    //           // this.frameUrl = `http://${HOST}/remote?address=${res.result.address}&cid=${res.result.cid}`
    //           // this.remotevisible = true
    //         }
    //       } catch (e) {
    //         console.log(e)
    //       } finally {
    //         this.loading = false
    //       }
    //     })
    //     .catch(() => {})
    // },

    publish(sn) {
      this.currentSn = sn
      this.selectAppVisible = true
    },
    delHandler(delMore, sn) {
      if (delMore && !this.multipleSelection.length) {
        this.$message.warning(this.$t('leastOne'))
        return
      }

      this.$confirm(
        delMore ? this.$t('deviceList.isDeleteDevice') : this.$t('deviceList.isDeleteThisDevice'),
        this.$t('tips'),
        {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel')
        }
      )
        .then(async () => {
          this.loading = true

          try {
            await this.$api.deviceManagement.deviceDelete(delMore ? this.multipleSelection : [sn])
            this.$message.success(this.$t('deleteSuccess'))
            this.$refs.table && this.$refs.table.clearSelection()
            this.renderData()
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }
        })
        .catch(() => {})
    },
    async _getGroupTree() {
      try {
        this.loading1 = true
        let res = await this.$api.deviceManagement.getGroupTree({})
        this.treeData = [res.result] || []
      } catch (e) {
        console.log(e)
      } finally {
        this.loading1 = false
      }
    },
    async newWindowOpen(row) {
      try {
        if (row.onlineStatus === 1) {
          this.$message.warning(this.$t('deviceList.deviceIsOffline'))
          return
        }
        this.loading = true
        const res = await this.$api.common.statisticAnalysis({ deviceSn: row.sn, remoteType: 1 })
        const d = res.result
        let windowName = this.$t('deviceList.remote')
        let width = screen.width
        let height = screen.height
        let p = `resizable=1,location=no,scrollbars=no,width=${width},height=${height},left=0,top=0`
        localStorage.setItem(`remote${row.sn}`, d)
        window.open(`${location.origin}${location.pathname}#/remoteSd?sn=${row.sn}`, windowName, p)
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .containHeader {
  margin: 15px 0 !important;
}
.remoteDialog {
  .content {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }

  .el-icon-warning {
    color: #f78a42;
    font-size: 32px;
    margin-right: 6px;
  }

  ::v-deep .el-dialog {
    margin-top: 40vh !important;
  }

  ::v-deep .el-dialog__body {
    padding: 6px 20px 4px !important;
  }

  ::v-deep .el-dialog__footer {
    text-align: right !important;
  }
}
.el-form-item {
  margin-bottom: 4px;
}
.con {
  display: flex;
  height: 100%;
  .tree {
    width: 20%;
    min-width: 240px;
    margin-right: 10px;
    height: 100%;
    overflow: auto;
    border: 1px solid #ebeef5;
    ::v-deep .el-tree-node__content {
      height: 32px;
      font-size: 14px;
    }
    .custom-tree-node {
      width: calc(100% - 24px);
      display: flex;
      justify-content: space-between;
      .name {
        width: calc(100% - 24px);
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .el-icon-sort {
        margin-right: 8px;
        // display: none;
        cursor: move;
      }
      // &:hover {
      //   .el-icon-sort {
      //     display: block;
      //   }
      // }
      .more {
        margin-right: 8px;
        // float: right;
        display: none;
      }
      &:hover {
        .more {
          display: inline-block;
        }
      }
    }
  }
  .table-wrap {
    // flex: 1;
    width: 70%;
  }
}

::v-deep .el-dropdown {
  margin-left: 10px;
}

.iframe-wrap {
  position: fixed;
  height: 100%;
  position: fixed;
  background: #fff;
  width: 100%;
  z-index: 999;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .self-header {
    display: flex;
    justify-content: flex-end;
    background: #dee1e6;
    padding: 6px;
    span {
      font-weight: bold;
      cursor: pointer;
    }
  }
}
.tips {
  line-height: 32px;
}
</style>
